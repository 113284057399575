$colorDarkBlue: #173c5d;
$colorSecondaryGray: #6c757d;
$colorGray: #2d2d2d;
$colorLightGray: lighten($colorGray, 70%);
$colorLightGrayBoxShadow: rgba(0, 0, 0, 0.3);
$headerHeight: 60px;

$hoverBlue: #dceffc;
$colorLightBlue: #deeaf5;
$colorBlue: #2673b9;
$colorLightGreen: #cdf0cd;
$colorGreen: #057c00;
$colorLightRed: #f5dede;
$colorRed: #b92626;

$colorWarning: #fff0b4;

$firmItBorderGray: #c4cfd8;
$firmItUltraLightGrey: #fafafa;
$firmItVeryLightGrey: #f4f4f4;
$firmItLightGrey: #eeeeee;
$firmItDarkGrey: #dddddd;
$firmItGray: #9d9fb4;
$firmItSemiLightBlue: #f1f3f9;
$firmItLightBlue: #f1f7fc;
$firmItDarkBlue: #3b516f;

//$fontFamily: "Helvetica Neue",Helvetica,Arial,sans-serif;
$fontFamily: "Inter", sans-serif;

$passive: rgba(100, 100, 100, 0.2);

$commentAccentColor: rgba(150, 150, 150, 0.5);

/* Shadow covers */
$scrollShadow: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
  linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
  /* Shadows */
    radial-gradient(
      farthest-side at 50% 0,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ),
  radial-gradient(
      farthest-side at 50% 100%,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    )
    0 100%;

$bg-light: rgb(239, 239, 243);

$transitionSpeed: 200ms;
$carousel-item-margin: 10px;

/* Dimension variables */
$navigation-width: 240px;
$navigation-padding: 20px;
$content-padding-y: 30px;

$footer-height: 80px;

$checkbox-width: 1.5rem;
$checkbox-height: 1.5rem;

$default_border_radius: 20px;
$default_border_radius_small: 10px;
$default_border_radius_very_small: 5px;

$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
