/*
 * DEPRECATED FILE
 * THIS IS ONLY USED IN THE TWIG-VUE-FRONTEND.
 * THERE IS A SIMILAR FILE IN THE ../vue folder
 */


.Vue-Toastification__toast--error {
  @apply bg-form-invalid rounded-none border-white;
}

/* Applied to the toast body when using regular strings as content */
.Vue-Toastification__toast-body.custom-class-1 {
  font-size: 30px;
}

/* Applied to a wrapper div when using a custom component as content */
.Vue-Toastification__toast-component-body.custom-class-2 {
  width: 100%;
}
