/* Shadow covers */
/* Dimension variables */
@tailwind base;
@tailwind components;
@tailwind utilities;
/*
 * DEPRECATED FILE
 * THIS IS ONLY USED IN THE TWIG-VUE-FRONTEND.
 * THERE IS A SIMILAR FILE IN THE ../vue folder
 */
.Vue-Toastification__toast--error {
  @apply bg-form-invalid rounded-none border-white;
}

/* Applied to the toast body when using regular strings as content */
.Vue-Toastification__toast-body.custom-class-1 {
  font-size: 30px;
}

/* Applied to a wrapper div when using a custom component as content */
.Vue-Toastification__toast-component-body.custom-class-2 {
  width: 100%;
}

.background {
  background-color: #f1f7fc;
  background-size: cover;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

body {
  min-height: 100vh;
}
body .nav-open {
  overflow: hidden;
}

.popover {
  max-width: 400px;
}

.cc-revoke {
  transform: rotate(-90deg) !important;
  right: -25px !important;
  bottom: 30px !important;
}

th:active, th:focus {
  outline: none !important;
}

p a {
  color: rgb(102, 175, 233);
}

.title-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 5px 0 10px 0;
}
.title-wrapper .action-bar-buttons > a {
  margin-right: 5px;
  margin-bottom: 5px;
}

h1,
h2,
h5,
h6 {
  font-weight: 700;
}

h3,
h4 {
  font-weight: 600;
}

h1 {
  font-size: 44px;
}

h2 {
  font-size: 34px;
}
h2.sm {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}
h3.sm {
  font-size: 22px;
  font-weight: 700;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.9rem;
}

h3 i {
  color: rgba(0, 0, 0, 0.5);
  transition: all 200ms ease;
}
h3 i:hover {
  color: black;
}

.header {
  padding: 0px 20px 15px;
  margin: 0px -30px 30px;
  border-bottom: 1px solid #dddddd;
}
.header div.page-title {
  display: inline-flex;
  align-items: center;
  height: 44px;
}
.header div.page-title h4 {
  margin: 0;
}
.header .nav-icon {
  width: 40px;
  height: 35px;
  margin-top: 15px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  float: left;
}
.header .nav-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #173c5d;
  border-radius: 3px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.header .nav-icon span:nth-child(1) {
  top: 0;
}
.header .nav-icon span:nth-child(2), .header .nav-icon span:nth-child(3) {
  top: 11px;
}
.header .nav-icon span:nth-child(4) {
  top: 22px;
}
.header .nav-icon.open span:nth-child(1) {
  top: 18px;
  width: 0;
  left: 50%;
}
.header .nav-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.header .nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.header .nav-icon.open span:nth-child(4) {
  top: 18px;
  width: 0;
  left: 50%;
}
.header .small-nav {
  float: right;
  font-size: 16px;
}
.header .small-nav div.dropdown {
  display: inline-block;
  margin-left: 10px;
}
.header .small-nav ul {
  margin-bottom: 0;
  float: right;
}
.header .small-nav .lang-disabled {
  opacity: 0.3;
}
.header .small-nav .lang-nav ul {
  margin-bottom: 0;
}
.header .small-nav .lang-nav ul li {
  margin: 2px 5px;
}
.header .small-nav li {
  list-style: none;
  float: left;
  margin: 10px 0 10px 20px;
}
.header .small-nav li a {
  color: #173c5d;
}

@media (max-width: 500px) {
  header .logo {
    width: 100px;
  }
}
.nav .nav-button {
  font-size: 14px;
  padding: 10px 15px;
  border: 2px solid black;
  transition: all 200ms ease;
  border-radius: 4px;
  color: black;
}
.nav .nav-button:hover {
  background: transparent;
  border-color: rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
}

.container-sm {
  width: 500px;
}

.register {
  margin: 80px auto;
}

.btn {
  font-size: 14px;
  font-weight: 700;
  padding: 10px 15px;
  transition: 200ms all ease;
  border-radius: 10px;
}
.btn.btn-sm {
  font-size: 12px;
  padding: 5px 10px;
}
.btn.btn-big {
  font-size: 16px;
  padding: 15px 20px;
}
.btn.disabled {
  cursor: default;
}

.btn-primary,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.disabled,
.btn-primary:disabled {
  color: white !important;
  background-color: #173c5d;
  border: 1.5px solid #173c5d;
}
.btn-primary:hover:not(.disabled),
.btn-primary:focus:hover:not(.disabled),
.btn-primary:active:hover:not(.disabled),
.btn-primary.disabled:hover:not(.disabled),
.btn-primary:disabled:hover:not(.disabled) {
  background-color: #3b516f;
}

button.btn-secondary,
button.btn-secondary:focus,
button.btn-secondary:active,
button.btn-secondary.disabled,
button.btn-secondary:disabled {
  background-color: #6c757d;
}

.btn-light {
  color: #173c5d;
  background-color: white;
  border: 1.5px solid #173c5d;
}
.btn-light:hover:not(.disabled) {
  background-color: #173c5d;
  color: white;
  border: 1.5px solid white;
}
.btn-light.back {
  border: 1.5px solid #173c5d;
  font-weight: 600;
}

.btn-success {
  background-color: #cdf0cd;
  border-color: #057c00;
  color: #057c00;
}
.btn-success .trash-icon {
  color: #057c00;
}
.btn-success:hover {
  color: white;
}
.btn-success:hover .trash-icon {
  color: white;
}

.btn-danger {
  background-color: #f5dede;
  border-color: #b92626;
  color: #b92626;
}
.btn-danger .trash-icon {
  color: #b92626;
}
.btn-danger:hover {
  color: white;
}
.btn-danger:hover .trash-icon {
  color: white;
}

a {
  color: #173c5d;
}
a:hover {
  color: #2673b9;
  text-decoration: none;
  cursor: pointer;
}
a.active {
  background: #173c5d;
  color: white;
}

a.card-layout, table.table tbody tr td a {
  display: inline-flex;
  min-width: 32px;
  min-height: 32px;
  padding: 0 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: #deeaf5;
  color: #2673b9;
  border: 1px solid #2673b9;
}
a.card-layout:hover, table.table tbody tr td a:hover {
  background-color: #2673b9;
  color: white;
  border-color: #2673b9;
}
a.card-layout.delete, table.table tbody tr td a.delete {
  background-color: #f5dede;
  color: #b92626;
  border-color: #b92626;
}
a.card-layout.delete:hover, table.table tbody tr td a.delete:hover {
  background-color: #b92626;
  color: white;
  border-color: #b92626;
}
a.card-layout:not(:last-child), table.table tbody tr td a:not(:last-child) {
  margin-right: 5px;
}

a.popover_link {
  color: rgba(0, 0, 0, 0.5);
  transition: all 200ms ease;
}
a.popover_link > i {
  color: rgba(0, 0, 0, 0.5);
}
a.popover_link:hover {
  color: black;
}

a.basic-link {
  color: #2673b9;
}
a.basic-link:hover {
  cursor: pointer;
}

.form-select, .form-control {
  color: #173c5d;
  border: 1.5px solid #173c5d;
  border-radius: 5px;
  height: 44px;
}

.form-control {
  padding: 0.5rem 0.75rem;
}

.form-control2 {
  background: none;
  box-shadow: none;
  border: none;
  border-bottom: 2px solid black;
  padding: 6px;
  height: 39px;
  font-size: 16px;
  font-weight: 300;
}
.form-control2:focus {
  box-shadow: none;
}

#table_founder_search_input {
  background: none;
  box-shadow: none;
  border: 1px solid #eeeeee;
}

#table_founder .arrow-icon-header {
  width: 25px;
}
#table_founder .arrow-icon {
  color: lightgray;
  width: 25px;
  height: 25px;
}

textarea.form-control {
  height: 200px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group > label,
.form-group > legend {
  display: inline;
}
.form-group a:hover {
  text-decoration: none;
}

.form-children-no-margin * .form-group {
  margin: 0;
}
.form-children-no-margin * .form-check {
  margin: 0;
}

.form-check {
  padding-left: 0;
}

.form-check-input {
  position: absolute;
  margin-left: 10px;
  z-index: -1;
  opacity: 0;
}

.form-check-label {
  position: relative;
  vertical-align: top;
}

.form-check-label::before {
  position: relative;
  float: left;
  margin: 0 5px;
  display: block;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #9d9fb4;
  width: 1.5rem;
  height: 1.5rem;
  top: -0.15rem;
  border-radius: 8px;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-check-label.must-be-checked::before {
  border-color: #b92626;
}

.form-check-label::after {
  position: absolute;
  display: block;
  margin: 0 5px;
  cursor: pointer;
  content: "";
  background: 50%/50% 50% no-repeat;
  width: 1.5rem;
  height: 1.5rem;
  top: -0.15rem;
}

.form-check-input:checked ~ .form-check-label::before {
  color: #fff;
  border-color: white;
  background-color: #173c5d;
}

.form-check-input.disabled ~ .form-check-label::before {
  opacity: 0.5;
}

.form-check-input.disabled ~ .form-check-label {
  pointer-events: none;
}

.form-check-input:checked ~ .form-check-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.form-check {
  margin-bottom: 0.5rem;
}

.lightblue-background {
  background-color: #f1f7fc;
}

.darkblue-background {
  background-color: #173c5d !important;
}

.ultra-lightgrey-background {
  background-color: #fafafa;
}

.very-lightgrey-background {
  background-color: #f4f4f4;
}

.lightgrey-background {
  background-color: #eeeeee;
}

.modal .content-box {
  min-height: auto;
}

.modal .modal-content {
  border-radius: 20px !important;
}

.content-box {
  padding: 20px 50px;
}
@media (max-width: 576px) {
  .content-box {
    padding: 20px 20px;
  }
}
.content-box .content-header {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 576px) {
  .content-box .content-header {
    flex-flow: row wrap;
  }
}
.content-box .action-bar {
  text-align: right;
  margin-bottom: 1em;
}
.content-box .toolbar {
  margin: -30px -50px;
  height: 60px;
  padding: 10px 10px;
}
.content-box .toolbar > li {
  list-style: none;
  display: inline-block;
  position: relative;
  float: right;
  margin-left: 10px;
}
.content-box .toolbar > li > a {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  border: 1px solid black;
  padding: 0 15px;
  color: black;
}
.content-box .toolbar > li > a:hover {
  background: rgba(0, 0, 0, 0.1);
  color: black;
}
.content-box .toolbar > li ul {
  visibility: hidden;
  transform: scaleY(0);
  transition: all 200ms ease;
  transform-origin: top;
  padding: 0;
  border: 1px solid black;
  background: white;
  position: absolute;
  z-index: 3;
}
.content-box .toolbar > li ul li {
  list-style: none;
}
.content-box .toolbar > li ul li a {
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  white-space: nowrap;
  color: black;
}
.content-box .toolbar > li:hover ul {
  visibility: visible;
  transform: scaleY(1);
}

.modal .content-box {
  padding: 0;
}
.modal .toolbar {
  display: none;
}

footer {
  display: flex;
  align-items: center;
  min-height: 80px;
  padding: 0 50px;
  margin: 2rem 0 0 240px;
  position: relative;
  bottom: 0;
  border-top: 1px solid #dddddd;
  font-size: 16px;
}
footer p,
footer ul {
  margin: 0;
  padding: 0;
}
footer ul li {
  list-style: none;
  float: left;
  margin-right: 10px;
}
footer ul li.separator {
  padding-right: 20px;
  border-right: 1px solid #3b516f;
}

.sidebar {
  padding: 0;
  margin-top: 60px;
  margin-right: -30px;
}
.sidebar li {
  background: rgba(255, 255, 255, 0.3);
  padding: 15px 20px;
  list-style: none;
}
.sidebar li a {
  color: black;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 300;
  transition: all 200ms ease;
}
.sidebar li:hover {
  background: rgba(255, 255, 255, 0.5);
}
.sidebar li.active {
  background: rgba(255, 255, 255, 0.7);
}

.step-list-container {
  width: fit-content;
}

.step-list,
.documents-list {
  padding: 0;
  margin: 0;
}
.step-list li,
.documents-list li {
  padding: 5px 20px;
  list-style: none;
  transition: all 200ms ease;
}
@media (max-width: 992px) {
  .step-list li,
  .documents-list li {
    padding: 5px 0;
  }
}
.step-list li a,
.documents-list li a {
  white-space: nowrap;
}
.step-list li a,
.step-list li span,
.documents-list li a,
.documents-list li span {
  color: #3b516f;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 300;
  transition: all 200ms ease;
  min-width: 23px;
  display: inline-block;
  text-align: center;
  vertical-align: -1px;
}
.step-list li i,
.step-list li svg,
.documents-list li i,
.documents-list li svg {
  margin-right: 5px;
}
.step-list li:hover a, .step-list li:hover span,
.documents-list li:hover a,
.documents-list li:hover span {
  color: rgba(102, 175, 233, 0.6);
}
.step-list li.active a, .step-list li.active span,
.documents-list li.active a,
.documents-list li.active span {
  font-weight: 600;
}

.documents-list {
  margin-top: 30px;
  margin-bottom: 30px;
}
.documents-list li {
  padding: 5px 0;
  color: black;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 300;
  transition: all 200ms ease;
}
.documents-list li a {
  font-size: 16px;
  white-space: initial;
  text-align: left;
}

.personen .person {
  position: relative;
  padding: 20px;
  background: rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}
.personen .person [data-remove] {
  position: absolute;
  top: 10px;
  right: 10px;
  color: black;
}
.personen [data-add] {
  margin: 20px 0;
  display: block;
  color: black;
}

.aktien .aktie {
  position: relative;
  padding: 20px;
  background: rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}
.aktien .aktie [data-remove] {
  position: absolute;
  top: 10px;
  right: 10px;
  color: black;
}
.aktien [data-add] {
  margin: 20px 0;
  display: block;
  color: black;
}

.popover-header {
  margin: 0;
}

.action-card {
  height: 200px;
  padding: 20px;
  text-align: center;
  color: black;
  transition: all 200ms ease;
  border: 2px solid black;
}
.action-card:hover {
  background: transparent;
  border-color: rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
}
.action-card .icon {
  font-size: 40px;
  margin-bottom: 20px;
}
.action-card .price {
  font-size: 28px;
}

p.price {
  margin: 0 10px 0 0;
  font-size: 28px;
}

.button-with-more-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.row-as-table .row {
  border-bottom: 1px solid black;
}
.row-as-table .row:first-child {
  border-top: 1px solid black;
}
.row-as-table .row [class*=col-] {
  border-right: 1px solid black;
}
.row-as-table .row [class*=col-]:first-child {
  border-left: 1px solid black;
}

.box {
  border: 1px solid black;
  padding: 1rem;
}

.logo-login {
  margin: 20px auto;
  max-width: 230px;
}

.nav-toggle {
  position: absolute;
  top: 15px;
  left: 0;
  margin: 10px 8px;
  padding: 0;
  z-index: 3;
  border: none;
  background: none;
}

nav {
  position: fixed;
  height: 100vh;
  min-width: 40px;
  z-index: 2;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  background-color: white;
}
@media screen and (max-width: 576px) {
  nav {
    min-height: auto;
  }
}
nav #nav {
  height: 100%;
}
nav #nav .nav-content {
  width: 240px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
  height: 100%;
}
nav #nav .nav-content a.btn-primary {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
nav #nav .nav-content div.logo {
  width: 180px;
  margin: auto;
}
nav #nav .nav-content div.divider {
  border-top: 1px solid #eeeeee;
  margin: 30px 0;
}
nav #nav .nav-content ul {
  padding: 0;
  margin: 0 0 50px;
  flex-grow: 1;
}
nav #nav .nav-content ul li {
  height: 60px;
  font-size: 16px;
}
nav #nav .nav-content ul li a {
  display: block;
  height: 100%;
  padding: 18px 0 0 11px;
  color: #173c5d;
  border-radius: 10px;
}
nav #nav .nav-content ul li a:hover {
  color: #2673b9;
}
nav #nav .nav-content ul li a.active {
  background: #f1f3f9;
}
nav #nav .nav-content ul li a.active::before {
  position: absolute;
  top: 0;
  left: -35px;
  width: 20px;
  height: 60px;
  border-radius: 10px;
  background: #173c5d;
  content: "";
}
nav #nav .nav-content ul li a p {
  margin: auto 10px;
  display: inline;
}
nav #nav .nav-content ul li a h6 {
  margin: -4px 0 0 28px;
  font-size: 12px;
  color: #dddddd;
}
nav #nav .nav-content div.navigation-footer {
  margin: 0 -20px -20px;
}
nav #nav .nav-content div.navigation-footer div.profile {
  width: 100%;
  padding: 0 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
nav #nav .nav-content div.navigation-footer div.profile.active > a {
  color: white;
  background-color: #173c5d;
}
nav #nav .nav-content div.navigation-footer div.profile > a {
  flex-grow: 1;
  padding: 4px 10px;
  color: #173c5d;
  border-radius: 8px;
}
nav #nav .nav-content div.navigation-footer div.profile > a p {
  font-size: 16px;
  margin: 0;
}
nav #nav .nav-content div.navigation-footer div.profile > a p:first-child {
  font-size: 12px;
}
nav #nav .nav-content div.navigation-footer div.profile span {
  display: flex;
  justify-content: flex-end;
  flex-grow: 0.1;
  font-size: 25px;
}
nav #nav .nav-content div.navigation-footer div.company-identifier {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  background-color: #f7fafc;
}
nav #nav .nav-content div.navigation-footer div.company-identifier p {
  font-size: 16px;
  margin: 0;
}

.main-content {
  margin-left: 240px;
}

@media (max-width: 1200px) {
  .main-content {
    margin-left: 40px;
  }
  footer {
    margin: 2rem 0 0 40px;
  }
  .navigation #nav .nav-content div.logo {
    position: relative;
    top: 5px;
    width: 70px;
  }
}
@media (min-width: 1200px) {
  #nav {
    display: block;
  }
}
.content-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: center;
}
@media (max-width: 992px) {
  .content-wrapper {
    flex-wrap: wrap;
  }
}
.content-wrapper > div:first-of-type {
  min-width: 50%;
  margin-bottom: 10px;
}
.content-wrapper > div:last-child {
  margin: 0 20px;
}
@media (max-width: 992px) {
  .content-wrapper > div:last-child {
    max-width: 100%;
  }
}

.help-label {
  margin-left: 0.33rem;
  color: #173c5d;
}
.help-label:hover {
  color: #2673b9;
}

.payment-method .form-check {
  border: 1px solid black;
  border-radius: 4px;
  margin-bottom: 5px;
  height: 50px;
  padding-top: 14px;
  padding-left: 30px;
}
.payment-method .form-check input {
  margin-top: 0.25rem;
}
.payment-method img {
  width: 50px;
  height: auto;
  float: right;
  margin-right: 5px;
}

div.tab-nav h4 {
  display: inline-block;
  margin: 0 40px 30px 0;
  padding-bottom: 7px;
  color: #9d9fb4;
  cursor: pointer;
}
div.tab-nav h4.active, div.tab-nav h4:hover {
  color: #173c5d;
}
div.tab-nav h4.active {
  border-bottom: 2px solid #2673b9;
}
div.tab-nav h4 a {
  color: inherit;
}

div.content-table {
  background-color: white;
  padding: 25px 0;
  border-radius: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}
div.content-table div.title-bar {
  display: flex;
  justify-content: space-between;
  padding: 0 30px 20px 30px;
  align-items: center;
}
div.content-table div.title-bar h2 {
  margin: 0;
  padding: 0;
}
div.content-table div.no-processes {
  padding: 0 30px;
}

div.filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 45px;
}
div.filter input {
  width: 60%;
  display: inline;
}
div.filter select {
  width: 70px;
}
div.filter label {
  margin: 2px 0 0 10px;
  max-width: 80px;
}

div.table-responsive {
  border-radius: 5px;
}

table.table {
  color: #173c5d;
}
table.table th:first-child,
table.table td:first-child {
  padding-left: 30px;
}
table.table th:last-child,
table.table td:last-child {
  padding-right: 30px;
}
table.table thead tr {
  height: 40px;
  background-color: #f1f3f9;
  font-size: 13px;
}
table.table thead tr th {
  vertical-align: middle;
  font-weight: 300;
  text-transform: uppercase;
}
table.table thead tr th span {
  margin-left: 0.5rem;
  cursor: pointer;
}
table.table thead tr th span span {
  margin-left: 0;
}
table.table tbody tr {
  height: 50px;
  background-color: white;
}
table.table tbody tr:hover {
  background-color: #dceffc !important;
}
table.table tbody tr td {
  vertical-align: middle;
}
table.table tbody tr td:last-child {
  text-align: end;
}
table.table tbody tr td:last-child div {
  min-width: 120px;
}
table.table-striped tbody tr:nth-of-type(odd) {
  --bs-table-striped-bg: #f1f7fc;
}

div ul.pagination {
  justify-content: end;
  margin: 25px 30px 0 0;
}
div ul.pagination li {
  margin: 0 3px;
}
div ul.pagination li.page-item.active .page-link {
  background-color: #173c5d;
  border-color: #173c5d;
  color: white;
}
div ul.pagination li .page-link {
  border-radius: 10px !important;
  color: inherit;
}

.table-offers td,
.table-offers th {
  padding: 0.2rem;
}
.table-offers h3 {
  padding: 0;
  margin-bottom: 10px;
  margin-top: 30px;
}
.table-offers strong {
  font-weight: 400;
}
.table-offers i,
.table-offers svg {
  margin-right: 0.66rem;
}

.init-summary-table {
  background-color: #eeeeee;
}
.init-summary-table div.summary-row-coloring:nth-child(odd) {
  background-color: #f1f7fc;
}

.yes-no-widget-header {
  margin-top: 1em;
}

.yes-no-widget .form-check {
  padding: 0;
}
.yes-no-widget .form-check input {
  text-align: center;
  padding: 0;
  margin: 0;
}
.yes-no-widget .choice {
  align-items: center;
  justify-content: center;
  display: flex;
}

.hidden {
  display: none;
}

.rounded-corners {
  border-radius: 5px;
}

.scrollbox {
  max-height: 500px;
  overflow-y: auto;
}

.big-scrollbox {
  max-height: 1000px;
  overflow-y: auto;
}

.horizontal-padding {
  padding-left: 30px;
  padding-right: 30px;
}

div.content {
  background-color: white;
  padding: 25px 30px;
  border-radius: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}
div.content.no-horizontal-padding {
  padding-left: 0;
  padding-right: 0;
}
div.content.extra-padding {
  padding: 40px 45px;
}

div.services label {
  display: block;
}
div.services input {
  display: inline-block;
}
div.services form {
  width: 100%;
}
div.services button.submit,
div.services a.submit {
  margin-top: 20px;
}
div.services select {
  display: inline-block;
  width: 500px;
}
div.services .submit-select {
  margin: -4px 0 0 25px;
  float: initial;
}

div.options {
  width: 100%;
  min-height: 20px;
  overflow: hidden;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-radius: 20px;
  border: 1px solid #dddddd;
}
div.options .option {
  position: relative;
  min-width: 20%;
  margin: 15px 1%;
  padding: 0 10px;
  height: 100px;
  border-radius: 20px;
  border: 2px solid #dddddd;
  background-color: #f1f3f9;
  cursor: pointer;
}
@media (max-width: 768px) {
  div.options .option {
    flex-grow: 1;
  }
}
div.options .option.disabled {
  cursor: not-allowed;
}
div.options .option:hover, div.options .option.selected {
  border: 2px solid #173c5d;
}
div.options .option.selected div.icon {
  visibility: visible;
}
div.options .option.option-payment {
  flex: 1 1 auto;
}
div.options .option.option-payment.option-large {
  flex: 3 1 auto;
}
div.options .option .payment-logo {
  margin-left: 10px;
  height: 40px;
}
div.options .option div.form-check {
  height: 100%;
  padding: 0;
}
div.options .option div.form-check input {
  display: none;
}
div.options .option div.form-check label {
  height: 100%;
  padding: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}
div.options .option div.form-check label:before, div.options .option div.form-check label:after {
  display: none;
}
div.options .option p.option-text {
  height: 100%;
  width: 100%;
  position: relative;
  top: -27px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
div.options .option div.icon {
  position: absolute;
  top: 5px;
  right: 12px;
  float: right;
  margin-left: -5px;
  font-size: 18px;
  visibility: hidden;
}
div.options .option-signature {
  width: 100%;
  height: 50px;
  margin: 10px 0;
}
div.options .option-signature div.form-check label {
  justify-content: left;
  text-align: left;
}
div.options .option-signature div.icon {
  top: 10px;
  font-size: 18px;
}
div.options .option-signature div.icon.disabled {
  opacity: 0.5;
}

div.signature_options {
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  padding: 15px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid #dddddd;
}
div.signature_options .option {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 2px solid #dddddd;
  background-color: #f1f3f9;
}
div.signature_options .option:not(:last-child) {
  margin-bottom: 10px;
}
div.signature_options .option div.icon {
  font-size: 18px;
}
div.signature_options .option:hover, div.signature_options .option.selected {
  border: 2px solid #173c5d;
}
div.signature_options .option div.phone-icon {
  border: 1px solid;
  border-radius: 20px;
  padding: 3px 12px;
  background-color: #9d9fb4;
  color: white;
}
div.signature_options .option div.phone-icon.mandatory {
  opacity: 0.5;
}
div.signature_options .option div.phone-icon.confirmed {
  background-color: #173c5d;
}
div.signature_options .option div.phone-icon .loading {
  margin: 0 -4px;
}
div.signature_options .option div.form-check {
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
div.signature_options .option div.form-check label {
  margin: 15px 10px;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
div.signature_options .option div.form-check label:before, div.signature_options .option div.form-check label:after {
  top: 0;
}
div.signature_options .option .esig-fields {
  display: flex;
  flex-direction: column;
  margin: 10px 35px 20px 35px;
}
div.signature_options .option .esig-fields .esig-field {
  display: flex;
  align-items: center;
}
div.signature_options .option .esig-fields .esig-field label {
  width: 50px;
  margin: 0 1rem 0 0;
}
div.signature_options .option .btn-danger {
  display: block;
  width: 165px;
}

div.mutation {
  padding-bottom: 20px;
}
div.mutation div.summary_data {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
}
div.mutation div.summary_data hr {
  height: 6rem;
  margin: auto -10px;
  /* border-reset*/
  border: none;
  border-left: 1px solid #dddddd;
}
div.mutation div.summary_data hr:last-child {
  /* there is one */
  display: none;
}
div.mutation div.persons {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
div.mutation div.persons div.person {
  margin: 5px;
  padding: 15px 20px;
  border-radius: 20px;
  border: 2px solid #dddddd;
  background-color: #f1f3f9;
}
div.mutation div.persons div.person p {
  margin: 0;
}

.bordered {
  border-radius: 20px;
  border: 1px solid #dddddd;
  padding: 15px;
}

.form-footer {
  margin: 20px -30px -30px -30px;
  padding: 25px 20px 30px;
  border-top: 1px solid #dddddd;
}
.form-footer .extra-margin {
  margin: 80px -30px -30px;
}
.form-footer button {
  float: right;
}

.modal-form-footer {
  margin: 20px -25px 0;
  padding: 30px 25px 0 25px;
  border-top: 1px solid #dddddd;
}
.modal-form-footer .extra-margin {
  margin: 80px -30px -30px;
}
.modal-form-footer button {
  float: right;
}

.modal-form-footer-margin {
  margin: 20px -15px -20px;
}

.switch-signature-wrapper {
  background-color: #f1f3f9;
  color: #173c5d;
  border-radius: 10px;
}
.switch-signature-wrapper .btn {
  border: 0;
}
.switch-signature-wrapper .active {
  box-shadow: none;
  background-color: #173c5d;
  color: #f1f3f9;
  border-radius: 10px !important;
}
.switch-signature-wrapper .form-check,
.switch-signature-wrapper label {
  margin: 0;
  padding: 0;
  cursor: pointer;
  min-height: 0;
}
.switch-signature-wrapper .form-check ::after,
.switch-signature-wrapper label ::after {
  content: none;
}
.switch-signature-wrapper .form-check ::before,
.switch-signature-wrapper label ::before {
  content: none;
}
.switch-signature-wrapper .new-label {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -14px;
  margin-right: -12px;
  z-index: 1;
  background-color: #fff0b4;
  border: 1px solid #8d670c;
  color: #8d670c;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
}

.mt-4-5 {
  margin-top: 2rem;
}

.open-esig {
  position: relative;
  border-radius: 20px;
  border: 1px solid #c4cfd8;
}
.open-esig:not(.open-esig:last-child) {
  margin-bottom: 15px;
}
.open-esig .upper {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
}
.open-esig .upper h6 {
  margin-bottom: 1rem;
}
.open-esig .upper p:last-child,
.open-esig .upper h4:last-child,
.open-esig .upper h6:last-child {
  margin: 0;
}
.open-esig .upper h6.gray {
  display: inline;
  margin-right: 10px;
  color: #9d9fb4;
}
.open-esig .lower {
  flex-direction: column;
  background-color: #f1f7fc;
  border-radius: 0 0 20px 20px;
  border-top: 2px solid #eeeeee;
  padding: 10px 20px;
}

.todo-item {
  display: flex;
  border-radius: 20px;
  border: 1px solid #c4cfd8;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
}
.todo-item:not(.todo-item:last-child) {
  margin-bottom: 15px;
}
.todo-item h5 {
  margin: 0;
}
.todo-item .todo {
  padding-right: 25px;
}
.todo-item .deadline {
  display: flex;
  align-items: center;
  height: 50px;
  border-left: 1px solid #eeeeee;
  padding-left: 25px;
}
.todo-item .deadline h6 {
  color: #9d9fb4;
}

.carousel {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}
.carousel .carousel-control {
  display: flex;
}
.carousel .carousel-control ol.carousel-indicators {
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  position: initial;
}
.carousel .carousel-control ol.carousel-indicators li {
  background-color: #9d9fb4;
  height: 15px;
  width: 15px;
  border-radius: 10px;
  border-top: none;
  border-bottom: none;
}
.carousel .carousel-control ol.carousel-indicators li.active {
  background-color: #173c5d;
}
.carousel .carousel-control .carousel-control-prev,
.carousel .carousel-control .carousel-control-next {
  position: relative;
  color: black !important;
  width: auto;
  border: none;
}
.carousel .carousel-inner {
  margin-left: -10px;
  width: calc(100% + 20px);
}
.carousel .carousel-item .item-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.carousel .carousel-item .item-container .item {
  max-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

h5.collapsible {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  cursor: pointer;
}
h5.collapsible svg {
  display: inline-block;
  transition: 0.3s ease-in-out all;
}
h5.collapsible.collapsed svg {
  transform: rotate(-180deg);
}

.login-background {
  background-color: #3b516f;
  background-blend-mode: multiply;
  background-size: cover;
  background-image: url("../../images/login_background.jpg");
}

.login-container-left,
.login-container-right {
  max-width: 600px;
}

.isBeingDeleted {
  opacity: 0;
  transition: 0.3s ease-in-out all;
}

.text-color-blue {
  color: #2673b9;
}

.text-danger {
  color: #b92626 !important;
}

.collection-widget {
  display: flex;
  font-size: 16px;
  height: 44px;
  width: 44px;
  padding: 0 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: #deeaf5;
  color: #2673b9;
  border: 1px solid #2673b9;
}
.collection-widget.delete {
  background-color: #f5dede;
  color: #b92626;
  border-color: #b92626;
}
.collection-widget.delete:hover {
  color: white;
  background-color: #b92626;
}

.date-fields {
  height: auto;
}
.date-fields select {
  margin-right: 5px;
}

.e-sig-document, .signer-invite {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  border: 1.5px solid #173c5d;
  border-radius: 20px;
}
.e-sig-document.declined, .signer-invite.declined {
  border: 1.5px solid #b92626;
}
.e-sig-document:not(:last-child), .signer-invite:not(:last-child) {
  margin-bottom: 1rem;
}
.e-sig-document.disabled, .signer-invite.disabled {
  border: 1.5px solid #c4cfd8;
}
.e-sig-document.disabled .document-name h5, .e-sig-document.disabled .signer-infos p, .signer-invite.disabled .document-name h5, .signer-invite.disabled .signer-infos p {
  color: #c4cfd8;
}
.e-sig-document .document-status, .e-sig-document .invite-details, .signer-invite .document-status, .signer-invite .invite-details {
  position: relative;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.e-sig-document .document-status .document-name, .e-sig-document .document-status .signer, .e-sig-document .invite-details .document-name, .e-sig-document .invite-details .signer, .signer-invite .document-status .document-name, .signer-invite .document-status .signer, .signer-invite .invite-details .document-name, .signer-invite .invite-details .signer {
  max-width: 50%;
}
.e-sig-document .document-status .document-name h5,
.e-sig-document .document-status .document-name p, .e-sig-document .document-status .signer h5,
.e-sig-document .document-status .signer p, .e-sig-document .invite-details .document-name h5,
.e-sig-document .invite-details .document-name p, .e-sig-document .invite-details .signer h5,
.e-sig-document .invite-details .signer p, .signer-invite .document-status .document-name h5,
.signer-invite .document-status .document-name p, .signer-invite .document-status .signer h5,
.signer-invite .document-status .signer p, .signer-invite .invite-details .document-name h5,
.signer-invite .invite-details .document-name p, .signer-invite .invite-details .signer h5,
.signer-invite .invite-details .signer p {
  margin-bottom: 0;
}
.e-sig-document .document-status .signer-infos, .e-sig-document .document-status .email-infos, .e-sig-document .invite-details .signer-infos, .e-sig-document .invite-details .email-infos, .signer-invite .document-status .signer-infos, .signer-invite .document-status .email-infos, .signer-invite .invite-details .signer-infos, .signer-invite .invite-details .email-infos {
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.e-sig-document a.show, .signer-invite a.show {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 10px;
  border-radius: 5px;
  background-color: #deeaf5;
  color: #2673b9;
  border: 1px solid #2673b9;
}
.e-sig-document a.show:hover, .signer-invite a.show:hover {
  background-color: #2673b9;
  color: white;
}
.e-sig-document a.show.declined, .signer-invite a.show.declined {
  background-color: #f5dede;
  color: #b92626;
  border: 1px solid #b92626;
}
.e-sig-document a.show.declined:hover, .signer-invite a.show.declined:hover {
  background-color: #b92626;
  color: white;
}

#modalSigningStatus div.e-sig-document {
  background-color: #f1f3f9;
}

div.qr-code {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
}

img.qr-code {
  height: 150px;
}

.identity-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.identity-card .uploaded {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.identity-card .uploaded .img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100px;
  margin-right: 10px;
  border-radius: 10px;
  border: 2px solid #3b516f;
}
.identity-card .uploaded .img-container img {
  position: absolute;
  max-height: 50px;
  max-width: 90px;
}
.identity-card .upload {
  width: 100%;
  padding: 0;
  overflow: hidden;
  margin-top: 1rem;
}
.identity-card .upload label {
  position: relative;
  height: 100%;
  width: 100%;
  border: 2px solid #3b516f;
  background-color: white;
}
.identity-card .upload label:hover, .identity-card .upload label.dragged-over {
  background-color: #f1f7fc;
}
.identity-card .upload label div:not(.hidden) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}
.identity-card .upload label div:not(.hidden) span {
  width: 60%;
}
.identity-card .upload label div:not(.hidden) span.icon {
  font-size: 40px;
}
.identity-card .upload label div:not(.hidden) img {
  max-height: 80px;
  max-width: 70px;
}
.identity-card .upload label input {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  display: block;
}
.identity-card .collection-widget {
  width: 35px;
  height: 35px;
  font-size: 14px;
}

.jur-person-signer {
  background-color: white;
  border: 1px solid #173c5d;
}
.jur-person-signer:hover {
  cursor: pointer;
  background-color: #dceffc;
}

.product-choice-label {
  border: 1px solid #173c5d;
  border-radius: 10px;
}
.product-choice-label:hover {
  background-color: #dceffc;
}

.evidence-iframe {
  width: 100%;
  min-height: 600px;
  border: none;
  border-radius: 20px;
  overflow: scroll;
}

.zoho-form-iframe {
  width: 100%;
  height: 80vh;
  border: none;
  border-radius: 20px;
  overflow: scroll;
}

.third-party-logo {
  width: 100px;
}

.text-success {
  color: #057c00 !important;
}

.bg-success {
  background-color: #cdf0cd !important;
}

.text-danger {
  color: #b92626 !important;
}

.bg-danger {
  background-color: #f5dede !important;
}

.price-overview {
  margin: -1.5rem 0 -1rem -1.5rem;
  width: calc(100% + 3.2rem);
  border-radius: 20px;
}

td:has(a) {
  margin-top: -0.5px;
}

.horizontal-divider {
  position: absolute;
  left: 50%;
  height: 80%;
  margin: 0 15px;
  border-left: 1.5px solid #c4cfd8;
}

.mirror-y {
  transform: rotateY(180deg);
}

.btn-close {
  @apply text-black;
  --bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  background: transparent var(--bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0px;
  opacity: 0.5;
}

.form-group {
  margin-bottom: 1em;
}

.collapse {
  visibility: visible;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.5rem;
  margin-top: 0;
  line-height: 1.2;
  color: inherit;
}

img, svg {
  display: initial;
}

p {
  margin-bottom: 1rem;
  margin-top: 0;
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

ul {
  padding-left: 2rem;
  list-style-type: disc;
}

ul ul {
  list-style-type: circle;
}

ul ul ul {
  list-style-type: square;
}

dl, ol, ul {
  margin-bottom: 1rem;
  margin-top: 0;
}

ol,
ul {
  padding-left: 2rem;
}

dl,
ol,
ul {
  margin-bottom: 1rem;
  margin-top: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

ol {
  list-style-type: decimal;
}
ol[type=a] {
  list-style-type: lower-alpha;
}