@use "../frontend/variables";

.modal {
  .modal-dialog {
    top: 10vh;
    margin: 0;
    padding-bottom: 100px;
    display: inline-block;
    left: 50%;
    min-width: 40em;
  }

  .modal-content {
    border-radius: 0;

    .modal-body .content-box {
      hr {
        display: none;

        &.show {
          display: block;
          margin: 30px -25px;
        }
      }
      .content-header {
        margin: 0 -25px 35px -25px;
        padding: 0 25px;
        border-bottom: 1px solid variables.$firmItDarkGrey;

        div:not(:last-child) {
          width: 100%;
        }

        h4 {
          max-width: 95%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }

  .modal-close {
    position: absolute;
    top: 24px;
    right: 18px;
    z-index: 10;
    cursor: pointer;
    font-size: 20px;
  }

  &.fade {
    .modal-dialog {
      transform: translateX(-50%) scale(0.8);
    }
  }

  &.show {
    .modal-dialog {
      transform: translateX(-50%) scale(1);
    }
  }

  .page-title {
    font-size: 1.25em;
    display: inline-block;
  }
}

.grayout {
  opacity: 0.6;
  filter: alpha(opacity=60);
}

.invisible {
  opacity: 0;
}

.form-group.submit-panel {
  margin-bottom: 0;
  padding: 12px 0 0;
  border-top: 1px solid #e5e5e5;
}

.prompt-form .col-sm-offset-4 {
  margin-left: 0;
}

.prompt-form .message-box {
  margin-bottom: 15px;
}

.btn-modal {
  min-width: 130px;
  outline: none !important;
}

.btn-submit {
  border: none;
  background: none;
  height: 40px;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  padding: 0;
  cursor: pointer;

  span {
    display: block;
    line-height: 1em;
    padding: 13px 10px;
    font-size: 0.9em;
    font-weight: 300;
    position: relative;

    &.submit-primary {
      background: #337ab7;
      color: white;
    }

    &:hover {
      background: #286090;
    }

    &.submit-danger {
      background: #d9534f;
      color: white;
    }

    &.submit-success {
      background: #5cb85c;
      color: white;
    }

    &.submit-default {
      background: rgb(221, 221, 221);
      color: black;
    }
  }
}
